import { OAuthIntegrationNameStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

export const oAuthIntegrationNameStorage = {
  save(integrationName: string): void {
    localStorage.set(OAuthIntegrationNameStorageKey, integrationName);
  },

  loadAndForget(): string | null {
    const oauthIntegrationName = localStorage.get(OAuthIntegrationNameStorageKey);
    localStorage.remove(OAuthIntegrationNameStorageKey);

    return oauthIntegrationName;
  },
};
