export enum TrackedEvent {
  AiAction = 'ai-action',
  AiImageDescription = 'ai-image-description',
  AiImageDescriptionTranslate = 'ai-image-description__translate',
  AiImageTagging = 'ai-image-tagging',
  AppLoaded = 'app--loaded',
  ClientTimeZoneUnsupported = 'client__timezone--unsupported',

  ApiKeyCreated = 'api-key--created',

  AssetDeleted = 'asset__deleted',
  AssetDownloaded = 'asset__downloaded',
  AssetRestored = 'asset__restored',
  AssetUpdated = 'asset__updated',
  AssetReplaceModalOpened = 'asset__replace-modal-opened',
  AssetReplaced = 'asset__replaced',
  AssetUploaded = 'asset__uploaded',
  AssetLimitedInRichText = 'asset__limited-in-rte',
  AssetLimitedInAssetElement = 'asset__limited-in-asset-element',
  AssetBulkAction = 'asset__bulk-action',

  AssetRenditionSaved = 'asset-rendition__saved',

  AssetDescriptionCopiedFromAnotherLanguage = 'asset-description__copied-from-another-language',

  AuditLogSearched = 'audit-log__searched',
  Calendar = 'calendar',
  Codename = 'codename',
  Comments = 'comments',
  Tasks = 'tasks',

  CollectionsUpdated = 'collections__updated',

  ContentComponentCollapse = 'content-component__collapse',

  ContentItemEditing = 'content-item-editing',

  ContentEntryBulkAssignment = 'content-entry__bulk-assignment',
  ContentEntryBulkDeleted = 'content-entry__bulk-deleted',
  ContentEntryBulkMoveToCollection = 'content-entry__bulk-move-to-collection',
  ContentEntryBulkPublished = 'content-entry__bulk-published',
  ContentEntryBulkScheduled = 'content-entry__bulk-scheduled',
  ContentEntryBulkUnpublished = 'content-entry__bulk-unpublished',
  ContentEntryBulkScheduleUnpublished = 'content-entry__bulk-schedule-unpublished',

  ContentEntryNameOrElementsChanged = 'content-entry__name-or-elements-changed',
  ContentEntryNewVersion = 'content-entry__new-version',
  ContentEntryLinkedItemExpanded = 'content-entry__modular-content--expanded',
  ContentEntryLinkedItemEdited = 'content-entry__modular-content--edited',

  ContentEntryOverwriteNotificationRefreshed = 'content-entry__overwrite-notification--refreshed',
  ContentEntryOverwriteNotificationDisplayed = 'content-entry__overwrite-notification--displayed',

  ContentEntryElementSaveConflict = 'content-entry__element-save-conflict',
  ContentEntryElementRefreshed = 'content-entry__element-refreshed',
  ContentEntryLockedElementOverwritten = 'content-entry__locked-element-overwritten',

  ContentItemDeleted = 'content-item--deleted',
  ContentItemLinkCopied = 'content-item--link-copied',

  ColumnSettings = 'column-settings',

  ContentListing = 'content-listing',

  ItemRevisionOpened = 'item-revision--opened',
  ItemRevisionRestored = 'item-revision--restored',
  ItemRevisionCompareOpened = 'item-revision-compare--opened',
  ItemSidebarToggle = 'item-sidebar--toggle',

  AssetTypeUpdated = 'asset-type--updated',

  ContentTypeCreated = 'content-type--created',
  ContentTypeDeleted = 'content-type--deleted',
  ContentTypeDuplicated = 'content-type--duplicated',
  ContentTypeUpdated = 'content-type--updated',
  ContentTypeSnippetCreated = 'content-type-snippet--created',
  ContentTypeSnippetDeleted = 'content-type-snippet--deleted',
  ContentTypeSnippetUpdated = 'content-type-snippet--updated',

  //Mission control
  MissionControlOpened = 'mission-control-opened',
  DashboardOpened = 'dashboard-opened',
  ContentStatusItemCreated = 'content-status-item--created',
  ContentStatusOpened = 'content-status--opened',
  ContentStatusContentItemClicked = 'content-status-content-item--clicked',

  ContentPaceOpened = 'content-pace--opened',
  ContentPaceWorkflowDetailOpened = 'content-pace-workflow-detail--opened',
  ContentPaceContentItemClicked = 'content-pace-content-item--clicked',

  MissionControlWidgetDetailItemClicked = 'mission-control-widget-detail-item--clicked',
  MissionControlWidgetShowMoreClicked = 'mission-control-widget-show-more--clicked',
  MissionControlWidgetOverviewItemClicked = 'mission-control-widget-overview-item--clicked',

  ContributorActivated = 'contributor--activated',
  ContributorDeactivated = 'contributor--deactivated',
  ContributorAssigned = 'contributor--assigned',

  CustomElementTypeUpsert = 'custom-element-type--upsert',

  CustomRoleCreated = 'custom-role--created',
  CustomRoleEdited = 'custom-role--edited',

  Environment = 'environment',

  Guidelines = 'guidelines',

  LanguageSwitched = 'language--switched',

  LocalizationLangVariantCreated = 'localization__lang-variant--created',

  NonLocalizableElementsWarningDisplayed = 'non-localizable-elements--warning-displayed',

  CurrentProjectContainerUpdated = 'current-project-container-updated',

  CurrentProjectUpdated = 'current-project-updated',

  PreviewConfigurationUpdated = 'preview-configuration--updated',

  ProjectOverview = 'project-overview',

  ProjectArchived = 'project--archived',
  ProjectCreated = 'project--created',
  ProjectDeleted = 'project--deleted',
  ProjectRestored = 'project--restored',

  ProjectSettingsClicked = 'project-settings-clicked',

  SampleProjectCreated = 'sample-project--created',

  RolesChanged = 'roles__changed',
  UserListingFilterChanged = 'user-listing-filter__changed',

  MissionControlUserFeedbackDialog = 'mission-control__user-feedback_dialog',
  MissionControlUserFeedbackDialogSent = 'mission-control__user-feedback_dialog--sent',

  SavedFilterSelected = 'saved-filter--selected',

  SitemapNodeCreated = 'sitemap__node--created',

  StartWalkthroughClicked = 'start-walkthrough-clicked',

  TaxonomyGroupArchived = 'taxonomy-group--archived',
  TaxonomyGroupCreated = 'taxonomy-group--created',
  TaxonomyTermAssigned = 'taxonomy-term--assigned',

  TemplateContentLimitExceeded = 'template-content--limit-exceeded',

  WebhookArchived = 'webhook--archived',
  WebhookEnabled = 'webhook--enabled',
  WebhookDisabled = 'webhook--disabled',
  WebhookReset = 'webhook--reset',
  WebhookSaved = 'webhook--saved',

  EntityWebhookSaved = 'new-webhook--saved',

  SpacesAppVisited = 'spaces-app--visited',
  SpacePreviewOpened = 'space--preview-opened',
  SpaceWebSpotlightSwitchSpace = 'space--web-spotlight-switch-space',

  WorkflowCreated = 'workflow--created',
  WorkflowUpdated = 'workflow--updated',
  WorkflowDeleted = 'workflow--deleted',
  WorkflowRestored = 'workflow--restored',
  DocsVisitManageWorkflows = 'docs--visit-manage-workflows',
  MultipleWorkflowsUpsellViewed = 'multiple-workflows--upsell-viewed',

  PlanDetailsOpened = 'plan-details-opened',

  ContentTypesWithURLSlugSelected = 'rte-configuration--content-types-with-url-slug-selected',

  MoveItemToArchivedStepConfirmationDialog = 'move-item-to-archived-step-confirmation-dialog',
  MoveItemsToArchivedStepConfirmationDialog = 'move-items-to-archived-step-confirmation-dialog',

  // Main menu events
  AvatarTypeChanged = 'avatar-type--changed',
  UserMenuOpened = 'menu__user-menu--opened',

  // General events
  FeatureUsed = 'feature--used',
  NavigationLinkOpened = 'navigation-link--opened',
  Statistics = 'statistics',
  FindRightContent = 'find-right-content',
  FindRightAsset = 'find-right-asset',
  LinkVisited = 'link--visited',

  // Experiments
  FeedbackSubmitted = 'feedback--submitted',
  ResourcesUsed = 'resources--used',

  UserFeedback = 'user-feedback--sent',
  UmuxSurvey = 'umux-survey',
  FeedbackTextModal = 'feedback-text-modal',
  StructuredUserFeedback = 'structured-user-feedback',

  // Content Item Editing
  ContentItemEditingOpened = 'content-item-editing--opened',
  CascadePublishNoSelectedItems = 'cascade-publish--no-selected-items',
  CascadePublishWithSelectedItems = 'cascade-publish--with-selected-items',
  CascadeScheduleNoSelectedItems = 'cascade-schedule--no-selected-items',
  CascadeScheduleWithSelectedItems = 'cascade-schedule--with-selected-items',
  CascadePublishUndo = 'cascade-publish-undo',
  CascadeScheduleUndo = 'cascade-schedule-undo',

  // Rich text editing
  RTECommandUsed = 'rte--command-used',

  // Relations tab
  RelationsTabOpened = 'relations-tab--opened',
  RelationsTabRootItemChanged = 'relations-tab--root-item-changed',
  RelationsTabDefaultRootItemChanged = 'relations-tab--default-root-item-changed',
  RelationsTabItemExpanded = 'relations-tab--item-expanded',

  // Web spotlight
  WebSpotlightOpened = 'web-spotlight--opened',
  WebSpotlightSubpageItemCreated = 'web-spotlight--subpage-item-created',
  WebSpotlightPossiblyIncorrectPlacementWarningShown = 'web-spotlight--possibly-incorrect-placement-warning-shown',
  WebSpotlightPreviewAddButtonActionClicked = 'web-spotlight__preview--add-button-action-clicked',
  WebSpotlightPreviewAddButtonClicked = 'web-spotlight__preview--add-button-clicked',
  WebSpotlightPreviewEditContentComponentClicked = 'web-spotlight__preview--edit-content-component-clicked',
  WebSpotlightPreviewEditContentItemClicked = 'web-spotlight__preview--edit-content-item-clicked',
  WebSpotlightPreviewEditElementClicked = 'web-spotlight__preview--edit-element-clicked',
  WebSpotlightPreviewResolutionChanged = 'web-spotlight__preview--resolution-changed',
  WebSpotlightPreviewShareLinkCreated = 'web-spotlight__preview--share-link-created',
  WebSpotlightPreviewShareLinkUsed = 'web-spotlight__preview--share-link-used',
  WebSpotlightUserChangesReadyOnPreviewApi = 'web-spotlight--user-changes-ready-on-preview-api',
}

export enum TrackedContentItemFilterPropertyName {
  Collections = 'collections',
  Contributors = 'contributors',
  PublishingState = 'publishing-state',
  RequiredElements = 'required-elements',
  Sitemap = 'sitemap',
  Spaces = 'spaces',
  Taxonomy = 'taxonomy',
  Type = 'type',
  Workflow = 'workflow',
}

export type FilterFindRightContentTrackedEventName<
  TPropertyName extends TrackedContentItemFilterPropertyName,
> = `content-list__filtered--by-${TPropertyName}`;

export const getFilterFindTheRightContentTrackedEventName = <
  TPropertyName extends TrackedContentItemFilterPropertyName,
>(
  trackedProperty: TPropertyName,
): FilterFindRightContentTrackedEventName<TPropertyName> =>
  `content-list__filtered--by-${trackedProperty}`;

export enum FindRightContentTrackedEvent {
  FilterLoaded = 'content-list__filter-loaded',
  FilteredByName = 'content-list__filtered--by-name',
  SortedByColumn = 'content-list__sorted',
  LoadedListingItemsFromServer = 'content-list__loaded-from-server',
  OpenedContentItemInventoryPage = 'opened-content-item-inventory-page',
  OpenedItemEditing = 'content-item__opened-editing',
  InsertedItemsFromModalSelector = 'content-items__inserted-from-modal',
  ModalSelectorCancelled = 'content-items__modal-cancelled',
}

export enum FindRightAssetTrackedEvent {
  AssetLibraryFilteredByName = 'assets-library__filtered-by-name',
  AssetLibraryFilteredByFolder = 'assets-library__filtered-by-folder',
  AssetLibraryFilteredByTaxonomy = 'assets-library__filtered-by-taxonomy',
  AssetLibraryFilteredByCollection = 'assets-library__filtered-by-collection',
  OpenedRoot = 'assets-library__opened-root',
  LoadedMoreAssets = 'assets-library__loaded-more-assets',
  OpenedModalSelector = 'assets-library__selector-opened',
  ClosedModalSelector = 'assets-library__selector-closed',
  InsertedAssetsFromModalSelector = 'assets-library__inserted-from-modal',
}

export enum WelcomePageTrackedEvent {
  SignupCompleted = 'signup--completed',
}

export enum TrackedStatistics {
  RichTextElement = 'rich-text-element',
}

export const IntercomTrackedEvents = [
  TrackedEvent.AppLoaded,
  TrackedEvent.AssetDeleted,
  TrackedEvent.AssetUpdated,
  TrackedEvent.AssetUploaded,
  TrackedEvent.Calendar,
  TrackedEvent.CascadePublishNoSelectedItems,
  TrackedEvent.CascadePublishWithSelectedItems,
  TrackedEvent.CascadeScheduleNoSelectedItems,
  TrackedEvent.CascadeScheduleWithSelectedItems,
  TrackedEvent.Comments,
  TrackedEvent.ContentEntryBulkPublished,
  TrackedEvent.ContentEntryLinkedItemEdited,
  TrackedEvent.ContentEntryNameOrElementsChanged,
  TrackedEvent.ContentEntryNewVersion,
  TrackedEvent.ContentItemDeleted,
  TrackedEvent.ContentItemEditing,
  TrackedEvent.ContentTypeCreated,
  TrackedEvent.ContentTypeDeleted,
  TrackedEvent.ContentTypeSnippetCreated,
  TrackedEvent.ContentTypeUpdated,
  TrackedEvent.CustomRoleCreated,
  TrackedEvent.CustomRoleEdited,
  TrackedEvent.FeatureUsed,
  TrackedEvent.FeedbackSubmitted,
  TrackedEvent.FindRightAsset,
  TrackedEvent.FindRightContent,
  TrackedEvent.ItemRevisionOpened,
  TrackedEvent.LocalizationLangVariantCreated,
  TrackedEvent.ProjectArchived,
  TrackedEvent.ProjectCreated,
  TrackedEvent.ResourcesUsed,
  TrackedEvent.RolesChanged,
  TrackedEvent.TaxonomyGroupCreated,
  TrackedEvent.TaxonomyTermAssigned,
  TrackedEvent.UserListingFilterChanged,
  TrackedEvent.SpacesAppVisited,
  WelcomePageTrackedEvent.SignupCompleted,
];
